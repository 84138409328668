import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const BlockchainContents = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="contents">
      <div className="ai-with-blockchain">
        <div className="inner">
          <div className="page-title">
            <h2 className="title" data-aos="fade-up">
              {t("blockchain.top.header")}
            </h2>
            <p data-aos="fade-up">
              {t("blockchain.top.content.1")} <br />
              {t("blockchain.top.content.2")}
            </p>
          </div>

          <div className="img">
            <p>{t("blockchain.img")}</p>
          </div>

          <div className="txt" data-aos="fade-up">
            <dl>
              <dt>{t("blockchain.txt1.header")}</dt>
              <dd>{t("blockchain.txt1.content")}</dd>
            </dl>
          </div>

          <div className="txt" data-aos="fade-up">
            <dl>
              <dt>{t("blockchain.txt2.header")}</dt>
              <dd>{t("blockchain.txt2.content")}</dd>
            </dl>
          </div>

          <div className="txt" data-aos="fade-up">
            <dl>
              <dt>{t("blockchain.txt3.header")}</dt>
              <dd>{t("blockchain.txt3.content")}</dd>
            </dl>
          </div>

          <div className="txt" data-aos="fade-up">
            <dl>
              <dt>{t("blockchain.txt4.header")}</dt>
              <dd>{t("blockchain.txt4.content")}</dd>
            </dl>
          </div>

          <div className="txt" data-aos="fade-up">
            <dl>
              <dt>{t("blockchain.txt5.header")}</dt>
              <dd>{t("blockchain.txt5.content")}</dd>
            </dl>
          </div>

          <div className="txt line" data-aos="fade-up">
            <p>{t("blockchain.txt6")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockchainContents;
