import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ContactContents = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="contents">
      <div className="contact">
        <div className="inner">
          <div className="page-title">
            <h2 className="title" data-aos="fade-up">
              {t("contact.header")}
            </h2>
            <p data-aos="fade-up">
              {t("contact.content.1")} <br />
              {t("contact.content.2")}
            </p>
          </div>

          <div className="img"></div>

          <div className="list">
            <div className="item" data-aos="fade-up">
              <dl>
                <dt>{t("contact.item1")}</dt>
                <dd>
                  <a href="mailto:jcg@jcglobeway.com">jcg@jcglobeway.com</a>
                </dd>
              </dl>
            </div>
            <div className="item" data-aos="fade-up">
              <dl>
                <dt>{t("contact.item2")}</dt>
                <dd>
                  <a href="mailto:jh@jcglobeway.com">jh@jcglobeway.com</a>
                </dd>
              </dl>
            </div>
            <div className="item" data-aos="fade-up">
              <dl>
                <dt>{t("contact.item3")}</dt>
                <dd>
                  <a href="mailto:bjkim@jcglobeway.com">bjkim@jcglobeway.com</a>
                </dd>
              </dl>
            </div>
            <div className="item" data-aos="fade-up">
              <dl>
                <dt>{t("contact.item4")}</dt>
                <dd>
                  <a href="mailto:help@jcglobeway.com">help@jcglobeway.com</a>
                </dd>
              </dl>
            </div>
            <div className="item" data-aos="fade-up">
              <dl>
                <dt>{t("contact.item5")}</dt>
                <dd>
                  <a href="mailto:jcg@jcglobeway.com">jcg@jcglobeway.com</a>
                </dd>
              </dl>
            </div>
          </div>

          <div className="txt" data-aos="fade-up">
            <p>
              {t("contact.txt1")}
              <br />
              {t("contact.txt2")}
            </p>
            <p>{t("contact.txt3")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactContents;
